<template>
    <LiefengContent>
        <template #title>更新确认</template>
        <template #toolsbarRight>
            <Button style="margin-right: 10px" type="primary" @click="searchBtn">全部更新</Button>
            <Button style="margin-right: 10px" type="info" @click="searchBtn">全部忽略</Button>
        </template>
        <template #contentArea>
            <LiefengTable :talbeColumns="talbeColumns" :tableData="tableData" :loading="loading" :fixTable="true" :curPage="page" :total="total" :pagesizeOpts="[20, 30, 50, 100]" :page-size="pageSize" @hadlePageSize="hadlePageSize"></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    data() {
        return {
            tableData: [],
            talbeColumns: [
                {
                    title: "修改项",
                    key: "staffName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "修改前",
                    key: "orgName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "修改后",
                    key: "accessName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "来源",
                    key: "accessName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    fixed: "right",
                    title: "操作",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: {
                                        marginLeft: "14px",
                                        // display:params.row.status == 3 ? 'none' : 'inline-block'
                                    },
                                    on: {
                                        click: () => {
                                            this.updata(params.row)
                                        },
                                    },
                                },
                                "更新"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginLeft: "14px",
                                        // display:params.row.status == 3 ? 'none' : 'inline-block'
                                    },
                                    on: {
                                        click: () => {
                                            this.ignore(params.row.id)
                                        },
                                    },
                                },
                                "忽略"
                            ),
                        ])
                    },
                },
            ],
            loading: false,
            pageSize: 20,
            total: 0,
            page: 1,
        }
    },

    methods: {
        //分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 获取列表
        getList() {
            this.loading = true
            this.$get("/gateway/api/auth/pc/sensitive/querySensitiveLog", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                page: this.page,
                pageSize: this.pageSize,
            })
                .then(res => {
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        this.tableData = res.dataList
                        this.total = res.maxCount
                        this.pageSize = res.currentPage
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                })
        },
        // 更新
        updata(id) {
            this.$post("", {
                id,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "更新成功",
                            background: true,
                        })
                    } else {
                        this.$Message.error({
                            content: res.decc,
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        content: "更新失败",
                        background: true,
                    })
                })
        },
        // 忽略
        ignore(id) {
            this.$post("", {
                id,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "更新成功",
                            background: true,
                        })
                    } else {
                        this.$Message.error({
                            content: res.decc,
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        content: "更新失败",
                        background: true,
                    })
                })
        },
    },
    created() {
        this.getList()
    },
}
</script>

<style scoped lang="less"></style>
